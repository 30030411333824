import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export const VideoSection = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="feature-3 mt-5">
          <div className="feature__wrap">
            <div className="mx-auto text-center">
              <h1 className="font-bold text-4xl mb-3 mx-auto" style={{ maxWidth: '800px' }}>
                Create elegant components, pages and dashboards for your projects using over 10000+
                components, 27+ example pages
              </h1>
              <p className="mb-4 mx-auto" style={{ maxWidth: '600px' }}>
                With Contrast Pro you can build beautiful, well crafted components and pages for
                your projects. Check out our pro admin template with over 27+ pages that ships
                together with Contrast Pro with icludes 10000+ components
              </p>
              <div className="mb-5 mx-auto">
                <a target="_blank" rel="noreferrer" href="https://cdbreact-pro-demo.netlify.app/">
                  <button className="btn btn-success mx-auto mr-2" style={{ display: 'inline' }}>
                    Preview
                  </button>
                </a>
                <AnchorLink href="#pricing">
                  <button className="btn btn-outline-success mx-auto" style={{ display: 'inline' }}>
                    Download
                  </button>
                </AnchorLink>
              </div>
              {/* <p className="mb-3 mx-auto"><small>There’s no way to describe it. You just have to see it for yourself.</small></p> */}
              <div>
                <video className="w-100 mx-auto" style={{ maxWidth: '920px' }} controls>
                  <source src={require('../../../components/images/demo1.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
