import React, { Fragment } from 'react';


export const Pricing = () => {
  const openPaymentOverlay = productType => {
    const paddle = window.Paddle;
    let productId;

    switch (productType) {
      case 'personal':
        productId = 745163;
        break;
      case 'team':
        productId = 745166;
        break;
      case 'enterprise':
        productId = 745167;
        break;
      default:
        productId = 0;
    }

    paddle.Checkout.open({
      product: productId,
      success: 'https://devwares.com/windframe/thankyou',
    });
  };

  return (
    <Fragment>
      <div className="container" style={{ margin: '10rem auto' }} id="pricing">
        <div className="comparison">
          <div className="comparison__title">
            <h2 className="comparison__lead">Choose the Best Plan for you</h2>
            <p className="comparison__sub-title text-center">
              <br />
              <strong>
                We are currently running limited sale which includes both the Contrast React
                Bootstrap PRO UI Library and a Premium Admin Template 🎉. Grab both now at a discounted price!
              </strong>
            </p>
          </div>
          <div className="comparison__wrap10" style={{ padding: '0rem 0.1%' }}>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <span className="comparison__price">$49</span>
                <span className="ml-2">
                  /<strike>$120</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Personal limited</h2>
              <p>Great for single personal use and for a side project.</p>
              <ul className="comparison__list2">
                <li className="good">Personal License</li>
                <li className="good">Limited to only one personal project</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/OdHKj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Pick for Limited Personal use
              </a>
            </div>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <span className="comparison__price">$69</span>
                <span className="ml-2">
                  /<strike>$179</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Personal Unlimited</h2>
              <p>Great for unlimited personal use and for multiple side projects.</p>
              <ul className="comparison__list2">
                <li className="good">Personal Unlimited License</li>
                <li className="good">Unlimited no of projects</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/OdHKj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
                style={{ fontSize: "13px" }}
              >
                Pick for Unlimited Personal use
              </a>
            </div>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <div className="comparison__price">$349</div>
                <span className="ml-2">
                  /<strike>$499</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Startup</h2>
              <p>Great for Startup Company/Projects </p>
              <ul className="comparison__list2">
                <li className="good">Team License</li>
                <li className="good">Team size: 2 - 8</li>
                <li className="good">Unlimited no of projects</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/OdHKj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </a>
            </div>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <div className="comparison__price">$749</div>
                <span className="ml-2">
                  /<strike>$989</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Small Business License</h2>
              <p>Best for sizeable scale uses.</p>
              <ul className="comparison__list2">
                <li className="good">Small Business License</li>
                <li className="good">Team size: 9 - 20</li>
                <li className="good">Unlimited no of projects</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Unlimited Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="https://gum.co/OdHKj"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </a>
            </div>
            {/* <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <div className="comparison__price">Enterprise License</div> */}
            {/* <span className="ml-2">/<strike>$949</strike></span>
                <span className="sale-price"> Sale Price</span> */}
            {/* </div> */}
            {/* <h2 className="comparison__package-name">Enterprise License</h2> */}
            {/* <p>Best for large scale uses and extended redistribution rights.</p>
              <ul className="comparison__list2">
                <li className="good">Enterprise License</li>
                <li className="good">Team size: Unlimited</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Unlimited Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <a
                href="/contact"
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Contact Us
              </a> */}
            {/* </div> */}
          </div>
        </div>
        <p className="comparison__footer-info">* Secured payment through Gumroad</p>
      </div>
    </Fragment>
  );
};
