import React from 'react';
import Showcase from '../../Showcase/Showcase.js';
import cardImage1 from '../../images/dashboard-freemium.png';
import cardImage2 from '../../images/asignin.png';
import cardImage3 from '../../images/acreateaccount.png';
import cardImage4 from '../../images/sign-up-form-1-3.png';
import cardImage5 from '../../images/aforgotpassword.png';
import cardImage6 from '../../images/sign-up-form-1-5.png';
import cardImage7 from '../../images/sign-up-form-1-6.png';
import cardImage8 from '../../images/sign-up-form-1-4.png';
import cardImage9 from '../../images/aprofile.png';
import cardImage10 from '../../images/atable.png';
import cardImage11 from '../../images/apostpage.png';
import cardImage14 from '../../images/dashboard-premium-1.png';
import cardImage15 from '../../images/dashboard-premium-dark.png';
import cardImage16 from '../../images/dashboard-premium.png';
import cardImage17 from '../../images/sign-up-form-1-1.png';
import cardImage18 from '../../images/sign-up-form-1-2.png';
import cardImage19 from '../../images/sign-up-form-1-7.png';
import cardImage20 from '../../images/header.png';
import cardImage21 from '../../images/forgot-password.png';
import cardImage23 from '../../images/sign-in.png';
import cardImage24 from '../../images/sign-in2.png';
import cardImage25 from '../../images/sign-up.png';
import cardImage26 from '../../images/sign-up2.png';
import cardImage27 from '../../images/dashboard-pro.png';
import cardImage28 from '../../images/about.png';
import cardImage29 from '../../images/about2.png';
import cardImage12 from '../../images/dashboard-freemium-1.png';
import cardImage13 from '../../images/dashboard-freemium-2.png';
import cardImage22 from '../../images/forgot-password-popup.png';


export const PagesShowcase = () => {
  const options = [
    {
      href: 'https://cdbreact-pro-demo.netlify.app',
      src: cardImage1,
      name: 'Dashboard',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/signin',
      src: cardImage24,
      name: 'Sign In',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/signin2',
      src: cardImage23,
      name: 'Sign In 2',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/signup',
      src: cardImage26,
      name: 'Sign Up',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/signup2',
      src: cardImage25,
      name: 'Sign Up 2',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/signin3',
      src: cardImage2,
      name: 'Sign In Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/forgot-password',
      src: cardImage21,
      name: 'Forgot Password',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/forgot-password2',
      src: cardImage5,
      name: 'Forgot Password 2',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/create-account',
      src: cardImage3,
      name: 'Create Account Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/prodashboard',
      src: cardImage27,
      name: 'Pro Dashboard',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/prodashboard2',
      src: cardImage15,
      name: 'Pro Dashboard Dark',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/prodashboard3',
      src: cardImage14,
      name: 'Pro Dashboard 3',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/prodashboard4',
      src: cardImage16,
      name: 'Pro Dashboard 4',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero',
      src: cardImage19,
      name: 'Hero 1',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero2',
      src: cardImage17,
      name: 'Hero 2',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero3',
      src: cardImage18,
      name: 'Hero 3',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero4',
      src: cardImage4,
      name: 'Hero 4',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero5',
      src: cardImage7,
      name: 'Hero 5',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/landing',
      src: cardImage20,
      name: 'Landing Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/hero404',
      src: cardImage6,
      name: '404 Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/pricing',
      src: cardImage8,
      name: 'Pricing Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/profilefree',
      src: cardImage9,
      name: 'Profile Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/profile',
      src: cardImage12,
      name: 'Profile Pro',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/chatui',
      src: cardImage13,
      name: 'Chat UI',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/table',
      src: cardImage10,
      name: 'Tables',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/post',
      src: cardImage11,
      name: 'Post Page',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/about',
      src: cardImage28,
      name: 'About',
    },
    {
      href: 'https://cdbreact-pro-demo.netlify.app/about2',
      src: cardImage29,
      name: 'About 2',
    },
  ];

  return (
    <div className="container">
      <div className="hero my-5 py-5">
        {/* <h4 className="text-center">Pages Preview</h4> */}
        <h2 className="font-bold text-4xl mb-3 text-center mb-4">Preview of Pages in Template</h2>
        <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
          Some well designed and crafted pages that you will find in the Contrast Premium Admin
          template
        </h5>
        <div
          className="mt-5 pt-5"
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '40px' }}
        >
          {options.map((item, index) => (
            <Showcase
              className="shadow"
              key={index}
              name={item.name}
              href={item.href}
              src={item.src}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
