import React from 'react';
import Showcase from '../../Showcase/Showcase.js';
import cardImage from '../../images/cardbg.png';

import styled from "../../../components/images/styledc.png";

export const Hero2 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/adminfeatfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Reusable well documented components and sections
            </h2>
            <p className="feature__text mb-5">
             Get access to well documented react bootstrap components that is frequently updated with fresh content to help explain concepts better.
    <br/>
    <br/>
                CDBREACT PRO has an extensive documentation with code examples of different react components and how they can be used in different scenarios.
               Easily copy the code examples directly into your own project to reproduce similar results.
            </p>
            {/* <a target="_blank" href="https://cdbreact-pro-demo.netlify.app/" className="btn1 btn__inverse">
              View components
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
