import React from 'react';
import styled from 'styled-components';

import '../../../styles/scss/style.scss';

const Wrapper = styled.p`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Number = styled.p`
  font-weight: 700;
  font-size: 3rem;
  color: rgb(29 78 216);
  text-align: center;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  text-align: center;
  text-transform: uppercase;
  color: rgb(31 41 55);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.1em;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const TextWrapper = styled.p`
  text-align: center;
  border-right: ${({ border }) => (border ? '1px solid #ccc' : '')} @media (max-width: 768px) {
    border-right: none;
  }
`;

export const Stats = () => {
  return (
    <div className="container">
      <div className="comparison__title">
        <h2 className="font-bold text-4xl text-center mb-4">Some Stats</h2>
      </div>
      <div className="mr-auto ml-auto py-5 px-3">
        <Wrapper>
          <TextWrapper border>
            <Number>85K+</Number>
            <Text>Installs</Text>
          </TextWrapper>
          <TextWrapper border>
            <Number>3000+</Number>
            <Text>Public Projects</Text>
          </TextWrapper>
          <TextWrapper border>
            <Number>10K+</Number>
            <Text>Different Component Combinations</Text>
          </TextWrapper>
          <TextWrapper>
            <Number>27+</Number>
            <Text>Templates</Text>
          </TextWrapper>
        </Wrapper>
      </div>
    </div>
  );
};
