import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/AboutPro/React/VideoSection';
import { Hero } from '../../components/AboutPro/React/Hero';
import { Hero3 } from '../../components/AboutPro/React/Hero3';
import { Hero2 } from '../../components/AboutPro/React/Hero2';
import { Hero4 } from '../../components/AboutPro/React/Hero4';
import { Feature } from '../../components/AboutPro/React/Feature';
import { Showcase } from '../../components/AboutPro/React/Showcase';
import { PagesShowcase } from '../../components/AboutPro/React/PagesShowcase';
import { DocsPreview } from '../../components/AboutPro/React/DocsPreview';
import { Comparison } from '../../components/AboutPro/React/Comparison';
import { Pricing } from '../../components/AboutPro/React/Pricing';
import { Cta } from '../../components/AboutPro/React/Cta';
import { Stats } from '../../components/AboutPro/React/Stats';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import FAQ from '../../components/AboutPro/React/Faq';
import '../../styles/scss/style.scss'

const ContrastPro = () => {

  return (
    <AppContext>
      <Helmet>
        <title>React Bootstrap PRO UI Kit library -  Contrast</title>
        <meta name="title" content="React Bootstrap PRO UI Kit library -  Contrast" />
        <meta
          name="description"
          content="React Bootstrap PRO Template and UI Kit Library for building responsive and modern web apps"
        />
        <meta
          property="og:title"
          content="React Bootstrap PRO UI Kit library -  Contrast"
        />

        <meta
          property="og:description"
          content="React Bootstrap PRO Template and UI Kit Library for building responsive and modern web apps"
        />
        <meta
          property="twitter:title"
          content="React Bootstrap PRO UI Kit library -  Contrast"
        />
        <meta
          property="twitter:description"
          content="React Bootstrap PRO Template and UI Kit Library for building responsive and modern web apps"
        />
        <link rel="canonical" href="https://www.devwares.com/product/contrast-pro/" />
        <meta property="og:url" content="https://www.devwares.com/product/contrast-pro/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643029480/react-bootstrap-contrast_uowqep.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <VideoSection />
            <div className="mt-5">
              <FeaturedIn />
            </div>
            <Feature />
            <Hero3 />
            <Hero2 />
            <Hero4 />
            
            <PagesShowcase />
            <DocsPreview />
            <Showcase />
            <Stats />
            <Comparison />
            <Pricing />
            <FAQ/>
            <Cta/>
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default ContrastPro;
