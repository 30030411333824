import React from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import Showcase from '../../Showcase/Showcase.js';
import cardImage from '../../images/cardbg.png';

import styled from "../../../components/images/styledc.png";

export const Hero = () => {

  return (
    <div className="py-5" style={{minHeight:"calc(100vh - 85px)"}}>
      <div  className="container">
        <div className="hero">
          <div className="feature__wrap">
            <div className="feature__left">
              <h2 className="feature__lead feature__lead--2">
                Contrast React Bootstrap UI Library (Pro) + Premium Admin Template
              </h2>
              <p className="feature__text">
                Elegant PRO UI Kit, React reusable components and Premium React Admin Template for building mobile-first, responsive webistes and web apps
              </p>
            
              <div className="feature__buttons">
                <AnchorLink href="#comparison" className="btn1 btn__inverse">
                  <span className="btn__text">Download</span>
                </AnchorLink>
                <a className="btn1 btn-white" target="_blank" rel="noreferrer" href="https://cdbreact-pro-demo.netlify.app/">
                  <span className="btn__icon">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0457 9.82757L8.34869 6.14804C8.03574 5.99838 7.68104 6.04213 7.44706 6.25908C7.21308 6.47602 7.14486 6.82459 7.27377 7.14506L8.51447 10.2331L7.56331 13.2171C7.46745 13.5179 7.56066 13.8628 7.80049 14.1026C7.8076 14.1097 7.81479 14.1168 7.82212 14.1237C8.07788 14.3639 8.43819 14.4416 8.73836 14.3212L16.1186 11.3601C16.4159 11.2407 16.5977 10.9495 16.5819 10.6176C16.566 10.2857 16.3555 9.97559 16.0457 9.82757ZM9.74123 12.054L10.2056 10.5971C10.2632 10.4165 10.2536 10.2154 10.1784 10.0285L9.57293 8.52147L13.6673 10.4788L9.74123 12.054Z"
                        fill="#3329e0"
                      />
                      <path
                        d="M10.9994 0C4.93431 0.00010632 0 4.48605 0 9.99989C0 15.5138 4.93431 19.9999 10.9995 20C17.0651 19.9999 21.9998 15.5139 21.9998 10C21.9998 4.48594 17.065 0 10.9994 0ZM10.9994 18.4051C5.90162 18.405 1.75416 14.6345 1.75416 9.99979C1.75416 5.36531 5.9015 1.59479 10.9994 1.59469C16.0977 1.59469 20.2454 5.36521 20.2454 9.99989C20.2455 14.6345 16.0977 18.405 10.9994 18.4051Z"
                        fill="#3329e0"
                      />
                    </svg>
                  </span>
                  <span className="btn__text" style={{color:"#3329e0"}}>Preview Template</span>
                </a>
              </div>
            </div>
            <div className="feature__right">
              <img
                src={require('../../../components/images/aboutherofree.png')}
                alt="#"
                className="feature__img"
              />
            </div>
          </div>

          <div className="hero__icons">
            <p className="hero__icon hero__icon--facebook">
              <svg width="53" height="53" viewBox="0 0 53 53" fill="none">
                <path
                  d="M33.028 29.505a5.504 5.504 0 00-3.809-1.21h-9.402v9.621h9.556a5.416 5.416 0 003.655-1.277 4.755 4.755 0 001.454-3.61 4.602 4.602 0 00-1.454-3.524zm-1.32-6.209a3.963 3.963 0 001.32-3.17 4.007 4.007 0 00-1.167-3.282 5.152 5.152 0 00-3.236-.946h-8.808v8.499h8.808a4.844 4.844 0 003.17-1.101h-.088zM44.037 0H8.808A8.808 8.808 0 000 8.808v35.23a8.808 8.808 0 008.808 8.807h35.23a8.808 8.808 0 008.807-8.807V8.808A8.808 8.808 0 0044.038 0zm-4.822 37.102a6.605 6.605 0 01-2.026 2.73 9.952 9.952 0 01-3.545 1.806c-1.665.49-3.395.72-5.13.682H14.356V11.494H29.33c2.35-.117 4.674.549 6.606 1.893a6.715 6.715 0 012.51 5.747 6.804 6.804 0 01-1.013 3.986 7.42 7.42 0 01-3.259 2.576 6.718 6.718 0 014.404 2.686 8.983 8.983 0 011.475 5.24 8.631 8.631 0 01-.836 3.48z"
                  fill="#828282"
                />
              </svg>
            </p>
            <p className="hero__icon hero__icon--html">
              <svg width="38" height="54" viewBox="0 0 38 54" fill="none">
                <path
                  d="M6.064 5.28H8.25v2.393h2.389V.528h-2.39v2.36H6.065V.529H3.676v7.145h2.388V5.28zM13.777 7.673h2.388V2.899h2.102V.528h-6.591v2.37h2.101v4.775zM21.65 4.133l1.646 2.54h.04l1.642-2.54v3.54h2.38V.528h-2.492l-1.53 2.514L21.805.528h-2.49v7.145h2.335v-3.54zM34.291 5.313h-3.358V.528h-2.388v7.145h5.746v-2.36zM.234 10.808l3.416 38.31 15.332 4.255 15.345-4.253 3.422-38.312H.234zM30.34 23.337H12.358l.43 4.811H29.91l-1.288 14.43-9.632 2.668-.006.002-9.626-2.67-.672-7.548h4.716l.35 3.915 5.233 1.414.006-.002 5.239-1.414.545-6.094H8.492L7.222 18.64h23.536l-.42 4.697z"
                  fill="#828282"
                />
              </svg>
            </p>
            <p className="hero__icon hero__icon--react">
              <svg width="60" height="54" viewBox="0 0 60 54" fill="none">
                <path
                  d="M30.079 32.198a5.307 5.307 0 100-10.613 5.307 5.307 0 000 10.613z"
                  fill="#828282"
                />
                <path
                  d="M30.078 39.048c-7.453 0-13.965-.878-18.839-2.548-3.16-1.078-5.846-2.516-7.759-4.154C1.45 30.611.372 28.72.372 26.89c0-3.51 3.848-6.946 10.307-9.187 5.286-1.84 12.178-2.865 19.388-2.865 7.083 0 13.88.994 19.134 2.812 3.076 1.058 5.666 2.432 7.506 3.954 1.998 1.67 3.055 3.5 3.055 5.286 0 3.647-4.303 7.336-11.237 9.63-4.905 1.628-11.46 2.527-18.447 2.527zm0-21.672c-6.84 0-13.606.994-18.553 2.717-5.941 2.072-8.605 4.905-8.605 6.798 0 1.966 2.865 5.063 9.144 7.21 4.61 1.575 10.846 2.41 18.014 2.41 6.723 0 12.992-.846 17.654-2.4 6.522-2.167 9.503-5.265 9.503-7.22 0-1.005-.76-2.189-2.146-3.34-1.596-1.333-3.922-2.548-6.702-3.51-5-1.713-11.502-2.665-18.31-2.665z"
                  fill="#828282"
                />
                <path
                  d="M18.038 53.34c-1.079 0-2.03-.232-2.833-.697-3.034-1.755-4.091-6.808-2.812-13.52 1.046-5.508 3.604-11.978 7.21-18.226 3.54-6.131 7.79-11.522 11.987-15.17 2.453-2.135 4.937-3.7 7.178-4.524 2.442-.899 4.556-.899 6.1-.01 3.16 1.818 4.207 7.4 2.727 14.546-1.046 5.074-3.541 11.195-7.03 17.252-3.72 6.449-7.738 11.65-11.618 15.043-2.516 2.199-5.105 3.806-7.473 4.64-1.237.445-2.4.667-3.436.667zm2.653-31.808l1.1.634c-3.415 5.92-5.941 12.273-6.914 17.432-1.174 6.184-.042 9.906 1.586 10.846.401.233.93.36 1.575.36 2.104 0 5.412-1.332 9.24-4.673 3.667-3.203 7.505-8.193 11.089-14.398 3.361-5.825 5.75-11.681 6.744-16.491 1.385-6.734.19-10.857-1.512-11.84-.866-.497-2.272-.434-3.964.19-1.956.719-4.165 2.125-6.385 4.06-3.985 3.467-8.055 8.626-11.46 14.525l-1.099-.645z"
                  fill="#828282"
                />
                <path
                  d="M42.117 53.373c-2.875 0-6.522-1.734-10.328-5.01-4.25-3.658-8.573-9.103-12.188-15.35-3.552-6.132-6.09-12.506-7.157-17.972-.624-3.192-.74-6.12-.339-8.478.444-2.569 1.491-4.398 3.045-5.296 3.15-1.83 8.51.053 13.965 4.905 3.869 3.436 7.928 8.658 11.427 14.705 3.732 6.448 6.237 12.527 7.231 17.58.645 3.277.75 6.322.296 8.795-.486 2.632-1.586 4.503-3.171 5.423-.793.465-1.734.698-2.78.698zM21.8 31.744c3.425 5.92 7.675 11.28 11.65 14.694 4.768 4.102 8.552 4.99 10.19 4.038 1.703-.983 2.95-5.01 1.66-11.522-.95-4.779-3.35-10.593-6.945-16.798-3.372-5.825-7.252-10.825-10.92-14.081-5.138-4.567-9.303-5.593-11.005-4.61-.867.497-1.511 1.755-1.818 3.531-.349 2.051-.243 4.673.328 7.559 1.014 5.19 3.446 11.29 6.86 17.189z"
                  fill="#828282"
                />
              </svg>
            </p>
            <p className="hero__icon hero__icon--css3">
              <img src={styled} alt="styled component icon" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
