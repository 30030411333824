import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from "react-anchor-link-smooth-scroll";


export const Hero4 = () => {

  return (
   <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">  
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Customize components using an extensive list of props and classes
            </h2>
            <p className="feature__text">
             CDBREACT PRO comes with a very extensive list of props for each react component. These props when applied results to various changes and adds new functionalities to various components.
             <br />
              <br />
            These props have been well documented on every react component page to give details about what type of value it expects, 
            examples of values that can be passed, exactly what these props do in your code and a written code example 
            </p>
            <br/><br/>
    
            <AnchorLink href="#pricing" className="btn1 btn__inverse">
              Get Contrast PRO
            </AnchorLink>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/aboutfeatpro.png')}to give details
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
